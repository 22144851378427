import React from 'react';
import templating from './templating';

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const story = { ...props.pageContext.story };
    story.content = JSON.parse(story.content);

    return { story };
  }

  constructor(props) {
    super(props);

    this.state = StoryblokEntry.prepareStory(props);
  }

  render() {
    const { content } = this.state.story;
    const contentType = this.state.story.content.component;

    return (
      <div>
        {templating(
          contentType,
          content,
          this.props.location,
          this.props.pageContext,
        )}
      </div>
    );
  }
}

export default StoryblokEntry;
